export const environment = {
  production: false,
  staging: true,
  apiEndpoint: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '7ee387d7-343b-4324-ad75-4fd8ff9d46d2',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: 'ae03ec27-71ac-45c3-817c-4a5334edcfec',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.staging.services4eo.com/'
};
